import { sscJobLinks } from "../../../../../pages/all-jobs-overview/ssc/SscLinks";
import { defenceJobLinks } from "../../../../../pages/all-jobs-overview/defence-sector/DefenceLink";
import { railwaysJobLinks } from "../../../../../pages/all-jobs-overview/railways/RailwaysLinks";
import { biharJobLinks } from "../../../../../pages/all-jobs-overview/bihar-based/BiharLinks";
import { upJobLinks } from "../../../../../pages/all-jobs-overview/up-based/UpLinks";
import { upscJobLinks } from "../../../../../pages/all-jobs-overview/upse/UpscLinks";
import { bankJobLinks } from "../../../../../pages/all-jobs-overview/banking-sector/BankLinks";
import { ntaJobLinks } from "../../../../../pages/all-jobs-overview/nta/NtaLink";

const LatestJobLinks = [
    // SSC Links
    ...Object.values(sscJobLinks),
    // Defence Sector Links
  ...Object.values(defenceJobLinks),
    //Railways
   ...Object.values(railwaysJobLinks) ,
   //Bihar
   ...Object.values(biharJobLinks),
   //UP
   ...Object.values(upJobLinks),
   //Upsc
   ...Object.values(upscJobLinks),
  // baking
   ...Object.values(bankJobLinks),
   ...Object.values(ntaJobLinks)
   
  ];

export default LatestJobLinks