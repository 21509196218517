import React from 'react'
import { Link } from 'react-router-dom';
import AdmissionLinks from '../Links/Admission';
const SeeMoreLinkAdmission = () => {
  return(
    <>
  
    <div className="container mx-auto p-4 pt-0 bg-slate-50">

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 m-2 lg:w-[60%] ">
        {
          AdmissionLinks.map((item, index) => (
            <div
              key={index}
              className="p-2 border border-gray 200 rounded-lg shadow-md hover:shadow-lg transition duration 200 text-center"
            >
              <Link
                to={item.url}
                className="text-sm lg:text-[16px] hover:text-blue-700 font-semibold text-blue-500" 
                
              >
                {item.linkLabel}
              </Link>
            </div>
          ))
        }
      </div>
    </div>
    </>
  );
}
export default SeeMoreLinkAdmission;

