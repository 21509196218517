import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CommingSoon from '../footer/CommingSoon';
import Home from './home/Home';
import LatestJob from '../pages/latest-job/LatestJob';
import AdmitCard from '../pages/admit-card/AdmitCard';
import SarkariYojana from '../pages/sarkari-yojana/SarkariYojana';
import Result from '../pages/result/Result';
import ContactUs from './contact/ContactUs';
import AboutUs from './about/AboutUs';
import Base from './all-link-structure/Base';
import PrivacyPolicy from './privacy-policy/PrivacyPolicy';
import { Helmet } from 'react-helmet';
import AdminPanel from '../admin/AdminPanel';

const BasicPagesRoutes = () => (
  <Routes>

    <Route path="/comming-soon" element={<CommingSoon/>} />

    <Route 
  path='/' 
  element={
    <>
      <Helmet>
        <title>SarkariLock</title>
        <meta name="description" content="Explore our Government Exam Information Portal for up-to-date details on exam dates, syllabi, eligibility criteria, and application processes. Stay informed on the latest job opportunities and government schemes." />
        <meta name="keywords" content="Government Exams, Exam Dates, Syllabus, Eligibility, Government Jobs, Exam Updates, Job Opportunities, Government Schemes" />
        <meta name="author" content="Your Name or Organization" />
      </Helmet>
      <Home />
    </>
  }
/>

    <Route path='/latest-job' element={<LatestJob />} />
    <Route path="/result" element={<Result />} />
    <Route path="/sarkari-yojana" element={<SarkariYojana />} />
    <Route path="/admit-card" element={<AdmitCard />} />
    <Route path="/contact-us" element={<ContactUs />} />
    <Route path="/about-us" element={<AboutUs />} />
    <Route path="/search-exam" element={<Base />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
  </Routes>
);

export default BasicPagesRoutes;
