import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const BpscAssistantTeacher = () => {
  const dateData = [
    { rowElement: 'Application Start On', rowElementresult: '28 September 2024' },
    { rowElement: 'Application Deadline', rowElementresult: '18 October 2024' },
    { rowElement: 'Pay Exam Fee Last Date', rowElementresult: '18 October 2024' },
    { rowElement: 'Exam Date', rowElementresult: '17 November 2024' },
    { rowElement: 'Admit Card Release', rowElementresult: 'Before Exam' },
  ];

  const ageLimitData = [
    { rowElement: 'Minimum Age', rowElementresult: '20, 21, or 22 Years (Post Wise)' },
    { rowElement: 'Maximum Age (Male)', rowElementresult: '37 Years' },
    { rowElement: 'Maximum Age (Female)', rowElementresult: '40 Years' },
    { rowElement: 'Age Relaxation', rowElementresult: 'As per BPSC 70 Pre Examination Rules' },
  ];

  const vacancyDetails = [
    { rowElement: 'BPSC Various Post', rowElementresult: '1945' },
    { rowElement: 'Child Development Project Officer', rowElementresult: '12' },
  ];

  const eligibilityData = [
    { rowElement: 'BPSC Various Post Eligibility', rowElementresult: 'Bachelor Degree in Any Stream from Any Recognized University in India' },
    { rowElement: 'Child Development Project Officer Eligibility', rowElementresult: 'Bachelor Degree with Home Science, Psychology, Social Science & Labor and Social Welfare' },
  ];

  const applicationFeeData = [
    { rowElement: 'General / OBC / Other State', rowElementresult: '₹600' },
    { rowElement: 'SC / ST / PH', rowElementresult: '₹150' },
    { rowElement: 'Female (Bihar Domicile)', rowElementresult: '₹150' },
    { rowElement: 'Fee Payment Mode', rowElementresult: 'Online / Offline Fee Mode Only' },
  ];

  const importantLinkData = [
    { rowElement: 'Apply Online', rowElementresult: 'Click Here', resultLink: 'https://onlinebpsc.bihar.gov.in/main/home' },
    { rowElement: 'Download Notification', rowElementresult: 'Click Here', resultLink: 'https://www.bpsc.bih.nic.in/Notices/NB-2024-09-23-02.pdf' },
    { rowElement: 'Download Syllabus (Pre / Mains)', rowElementresult: 'Click Here', resultLink: 'https://drive.google.com/file/d/19yxqJ0bP2g_2MizzIUQwugF5-fUgLdDj/view?usp=sharings' },
  ];

  const categoryWiseVacancyDetail = [
    { postName: 'Sub-Divisional Officer', ur: 80, ews: 20, ebc: 36, bc: 24, bc_female: 6, sc: 32, st: 2, total: 200 },
    { postName: 'Deputy Superintendent of Police', ur: 52, ews: 13, ebc: 24, bc: 12, bc_female: 6, sc: 28, st: 1, total: 136 },
    { postName: 'Assistant Commissioner of State Taxes', ur: 65, ews: 16, ebc: 26, bc: 25, bc_female: 4, sc: 31, st: 1, total: 168 },
    { postName: 'Supply Inspector', ur: 89, ews: 22, ebc: 39, bc: 29, bc_female: 13, sc: 37, st: 4, total: 233 },
    { postName: 'Block SC and ST Welfare Officer', ur: 71, ews: 13, ebc: 13, bc: 14, bc_female: 2, sc: 10, st: 2, total: 125 },
  ];

  return (
    <>
      <Createnewjob
      
        mainHead={"BPSC 70th Combined Pre Exam CCE / CDPO Recruitment 2024"}
        head={"BPSC 70th Combined Pre Exam CCE / CDPO Recruitment 2024"}
        ageLimitData={ageLimitData}
        eligibilityData={eligibilityData}
        dateData={dateData}
        importantLinkData={importantLinkData}
        applicationFeeData={applicationFeeData}
        vacancyDetails={vacancyDetails}
        categoryWiseVacancyDetail={categoryWiseVacancyDetail}
      />
    </>
  );
};

export default BpscAssistantTeacher;
