import React from 'react'
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';
const Ssccgl = () => {
    const dateData = [
        { rowElement: 'Exam start on ', rowElementresult: '09 September2024' },
        { rowElement: 'Exam End on', rowElementresult: '23 september 2024' },
        { rowElement: 'Admit Card Release Date', rowElementresult: '04 September 2024' },
        { rowElement: 'Exam Date', rowElementresult: '09 to 23 september 2024' },
      ];
     // Dummy Data for Age Limit
    const ageLimitData = [
      { rowElement: 'Assistance Audit officer', rowElementresult: '18-30 Years' },
      { rowElement: 'Assistance accountant officer', rowElementresult: '18-30 Years' },
      { rowElement: 'Inspector of Income Tax', rowElementresult: '18-30 Years' },
      { rowElement: 'Inspector (Central Excise/Preventive Officer)', rowElementresult: '18-30Years' },
{ rowElement: 'Sub Inspector (Central Bureau of Investigation - CBI)', rowElementresult: '18-30Years' },
{ rowElement: 'Sub Inspector (National Investigation Agency - NIA)', rowElementresult: '18-30Years' },

    ];
     // Dummy Data for Details
    const vacancyDetails = [
      { rowElement: 'SSc cgl Grade A', rowElementresult: '500' },
      { rowElement: 'SSc cgl Grade B', rowElementresult: '720' },
 
    ];
    
    // Dummy Data for Eligibility Criteria
    const eligibilityData = [
      { rowElement: 'Educational Qualification', rowElementresult: 'Bachelor’s Degree in Relevant Field' },
      { rowElement: 'Experience Required', rowElementresult: 'Minimum 2 Years' },
      { rowElement: 'Nationality', rowElementresult: 'Indian' },
      { rowElement: 'Language Proficiency', rowElementresult: 'English and Hindi' },
    ];
    
    // Dummy Data for Application Fee
    const applicationFeeData = [
      { rowElement: 'General/OBC Fee', rowElementresult: '₹100' },
      { rowElement: 'SC/ST Fee', rowElementresult: '₹0' },
      { rowElement: 'Application Fee for Females', rowElementresult: '₹0' },
      { rowElement: 'Fee Payment Mode', rowElementresult: 'Online Only' },
    ];
    
    // Dummy Data for Important Links
    const importantLinkData = [

      { rowElement: 'Answer key', rowElementresult: 'Check Here' ,resultLink:'https://ssc.digialm.com//EForms/loginAction.do?subAction=ViewLoginPage&formId=89695&orgId=32874'},
      { rowElement: 'Official Notification', rowElementresult: 'Download Here' ,resultLink:'https://ssc.gov.in'},
      { rowElement: 'Application Form', rowElementresult: 'Apply Online' , resultLink:'https://ssc.gov.in/home/apply'},
      { rowElement: 'Admit Card Download', rowElementresult: 'Access Here' , resultLink:'https://ssc.nic.in/Portal/AdmitCard'},
      { rowElement: 'Results Announcement', rowElementresult: 'Check Results' , resultLink:'https://ssc.gov.in/home/candidate-result'},
    ];
     
    const categoryWiseVacancyDetail = [
      { postName:'SSC-GL ' ,ur:4500,sc:1000,st:550,obc:2000,ews:800},
    
    ]

  return (
    <><Createnewjob 

    mainHead={"SSC Junior Engineer (Civil / Mechanical / Electrical) Examination 2024 Result"}
    head={"SSC Junior Engineer (Civil/Mechanical/Electrical) Examination 2024 Result"}
    ageLimitData={ageLimitData} eligibilityData={eligibilityData} dateData={dateData}  importantLinkData={importantLinkData} applicationFeeData={applicationFeeData} vacancyDetails={vacancyDetails} categoryWiseVacancyDetail={categoryWiseVacancyDetail} />
    </>
  )
}
export default  Ssccgl;
