import React from 'react'
import ExploreLink from './ExploreLink';
import LatestJobLinks from './Links/LastestJob'
import AdmitCardLinks from './Links/AdmitCard'
import ResultLinks from './Links/Result'
import SyllabusLinks from './Links/Syllabus'
import AnswerKeyLinks from './Links/AnswerKey'
import AdmissionLinks from './Links/Admission'
const ExploreList = () => {
    

  return (
    <>
     <p className='text-2xl underline text-center font-bold mt-4'>EXPLORE</p>
<section className='flex flex-wrap sm:gap-6 justify-center sm:p-4 mt-2'>
    <ExploreLink links={LatestJobLinks} title={"Latest Jobs"} linkPage='/job/latest-job/all-links'/>
<ExploreLink links={AdmitCardLinks} title={"Admit Cards"} linkPage='/job/admit-card/all-links'/>
    <ExploreLink links={ResultLinks} title={"Result"} linkPage='/job/result/all-links'/>
    <ExploreLink links={SyllabusLinks} title={"Syllabus"} linkPage='/job/syllabus/all-links'/>
    <ExploreLink links={AnswerKeyLinks} title={"AnswerKey"} linkPage='/job/answer-key/all-links'/>
    <ExploreLink links={AdmissionLinks} title={"Admission"} linkPage='/job/admission/all-links'/>
        </section>
   
        </>
  )
}
export default ExploreList;