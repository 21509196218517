import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const IbpsRrb = () => {
    const dateData = [
        { rowElement: 'Application Begin', rowElementresult: '7 June 224' },
        { rowElement: 'Last Date for Apply Online', rowElementresult: '3 June 224' },
        { rowElement: 'PET Admit Card Available', rowElementresult: '22 July 224' },
        { rowElement: 'Exam Date Prelim', rowElementresult: 'August 224' },
        { rowElement: 'Officer Scale I Phase I Available', rowElementresult: '13 September 224' },
        { rowElement: 'Officer Scale II Phase II Admit Card', rowElementresult: '19 September 224' },
    ];

    const ageLimitData = [
        { rowElement: 'Office Assistant', rowElementresult: '18-28 Years' },
        { rowElement: 'Officer Scale I', rowElementresult: '18-3 Years' },
        { rowElement: 'Senior Manager Officer Scale III', rowElementresult: '21-4 Years' },
        { rowElement: 'Other Posts', rowElementresult: '21-32 Years' },
    ];

    const vacancyDetails = [
        { rowElement: 'Total Vacancy', rowElementresult: '9995 Posts' },
    ];

    const eligibilityData = [
        { rowElement: 'Office Assistant', rowElementresult: 'Bachelor Degree in Any Stream from Any Recognized University in India' },
        { rowElement: 'Officer Scale I', rowElementresult: 'Bachelor Degree in Any Stream from Any Recognized University in India' },
        { rowElement: 'Officer Scale II General Banking Officer', rowElementresult: 'Bachelor Degree in Any Stream with Minimum 5% Marks and 2 Years Experience' },
        { rowElement: 'Officer Scale II IT Officer', rowElementresult: 'Bachelor Degree in Electronics / Communication / Computer Science / Information Technology with Minimum 5% Marks and 1 Year Experience' },
        { rowElement: 'Officer Scale II Chartered Accountant', rowElementresult: 'Passed C.A. Examination from ICAI India with 1 Year Experience as a CA' },
        { rowElement: 'Officer Scale II Law Officer', rowElementresult: 'Bachelor Degree in Law (LLB) with Minimum 5% Marks and 2 Years Advocacy Experience' },
        { rowElement: 'Officer Scale II Treasury Officer', rowElementresult: 'Degree in CA OR MBA Finance with 1 Year Experience' },
        { rowElement: 'Officer Scale II Marketing Officer', rowElementresult: 'MBA Degree in Marketing with 1 Year Experience' },
        { rowElement: 'Officer Scale II Agriculture Officer', rowElementresult: 'Bachelor Degree in Agriculture/ Horticulture/ Dairy/ Veterinary Science / Engineering with 2 Years Experience' },
        { rowElement: 'Officer Scale III', rowElementresult: 'Bachelor Degree in Any Stream with Minimum 5% Marks and 5 Years Experience' },
    ];

    const applicationFeeData = [
        { rowElement: 'General / OBC', rowElementresult: '₹85' },
        { rowElement: 'SC / ST / PH', rowElementresult: '₹175' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'Online Fee Mode (Debit Card, Credit Card, Net Banking, Mobile Wallet, E-Challan, Cash Card)' },
    ];

    const importantLinkData = [
        { rowElement: 'Download Admit Card (Officer Scale I Phase II Mains)', rowElementresult: 'Click Here', resultLink: 'https://ibpsonline.ibps.in/rrbxiiimay24/eocla_sept24/login.php?appid=d2bce8ebb007687ad8bcaa5309973557' },
        { rowElement: 'Download Admit Card (Officer Scale II & III)', rowElementresult: 'Click Here', resultLink: 'https://ibpsonline.ibps.in/rrbxiiimay24/eocla_sept24/login.php?appid=d2bce8ebb007687ad8bcaa5309973557' },
        { rowElement: 'Download Phase I Score Card (Officer Scale I)', rowElementresult: 'Click Here', resultLink: 'https://ibpsonline.ibps.in/rrbxiiimay24/disc1_sep24/login.php?appid=05b8b51692a77824f3860990bb458e67' },
        { rowElement: 'Download Phase I Result (Officer Scale I)', rowElementresult: 'Click Here', resultLink: 'https://ibpsonline.ibps.in/rrbxiiimay24/disc1_sep24/login.php?appid=05b8b51692a77824f3860990bb458e67' },
        { rowElement: 'Download Pre Exam Admit Card', rowElementresult: 'Click Here', resultLink: 'https://ibpsonline.ibps.in/rrbxiiimay24/oecla_jul24/downloadClose.php' },
        { rowElement: 'Apply Online', rowElementresult: 'Click Here', resultLink: 'https://ibpsonline.ibps.in/rrb13oamay24/' },
        { rowElement: 'Download Notification', rowElementresult: 'Click Here', resultLink: 'https://drive.google.com/file/d/1-ovPY3-kMu6FWY_6lUXDEuOXLPMwpT-t/view?usp=sharing' },
        { rowElement: 'Download Syllabus', rowElementresult: 'Click Here', resultLink: 'https://pdf.ac/2jd8P0' },
    ];

    const categoryWiseVacancyDetail = [
        { postName: 'Office Assistant', ur: 2332, ews: 536, obc: 1313, sc: 938, st: 466, total: 5585 },
        { postName: 'Officer Scale I', ur: 1453, ews: 338, obc: 955, sc: 513, st: 24, total: 3499 },
        { postName: 'General Banking Officer Scale II', ur: 21, ews: 43, obc: 14, sc: 77, st: 35, total: 496 },
        { postName: 'IT Officer Scale II', ur: 54, ews: 3, obc: 23, sc: 11, st: 3, total: 94 },
        { postName: 'Chartered Accountant Scale II CA', ur: 32, ews: 4, obc: 15, sc: 8, st: 1, total: 6 },
        { postName: 'Law Officer II', ur: 25, ews: 1, obc: 3, sc: 1, st: 0, total: 3 },
        { postName: 'Treasury Officer Scale II', ur: 16, ews: 0, obc: 5, sc: 0, st: 0, total: 21 },
        { postName: 'Marketing Officer Scale II', ur: 8, ews: 0, obc: 2, sc: 1, st: 0, total: 11 },
        { postName: 'Agriculture Officer Scale II', ur: 33, ews: 6, obc: 18, sc: 9, st: 4, total: 7 },
        { postName: 'Officer Scale III', ur: 66, ews: 1, obc: 34, sc: 13, st: 6, total: 129 },
    ];

    return (
        <Createnewjob 
            mainHead={"IBPS Rural Regional Bank (RRB) XIII Recruitment 224 Notification"}
            head={"IBPS RRB XIII 224 Recruitment for Various Office Assistant and Officer Scale I, II, III Posts"}
            ageLimitData={ageLimitData} 
            eligibilityData={eligibilityData} 
            dateData={dateData}  
            importantLinkData={importantLinkData} 
            applicationFeeData={applicationFeeData} 
            vacancyDetails={vacancyDetails} 
            categoryWiseVacancyDetail={categoryWiseVacancyDetail}
        />
    );
}
export default IbpsRrb;
