import React from 'react';
import Createnewjob from '../../../basic-pages/job-link-detail.jsx/Createnewjob';

export const AirForceCommonAdmissionTest = () => {
    const dateData = [
        { rowElement: 'Application Start On', rowElementresult: '30 may 2024' },
        { rowElement: 'Application Deadline', rowElementresult: '28 june 2024' },
        { rowElement: 'Admit Card Release Date', rowElementresult: '24 july 2024' },
        { rowElement: 'Exam Date', rowElementresult: 'As per schedule' },
    ];

    // Age Limit Data
    const ageLimitData = [
        { rowElement: 'Minimum Age', rowElementresult: '20 Years' },
        { rowElement: 'Maximum Age', rowElementresult: '24 To 26 Years' },
    ];

    // Vacancy Details
    const vacancyDetails = [
        { rowElement: 'Flying Branch', rowElementresult: '29' },
        { rowElement: 'Ground Duty', rowElementresult: '156' },
        { rowElement: 'Ground Duty (Non-Technical)', rowElementresult: '83' },
        { rowElement: 'Ground Duty (Non-Technical,education ,wepon )', rowElementresult: '26' },
        { rowElement: 'Meteorology Branch', rowElementresult: '10' },
    ];

    // Eligibility Criteria
    const eligibilityData = [
        { rowElement: 'Educational Qualification', rowElementresult: 
          'Candidate must have passed 10+12 from Science stream with a minimum of 60% marks. A Graduation degree or a 3/4 year degree with 60% marks is required for some branches.' },
        { rowElement: 'Experience Required', rowElementresult: 'No Experience' },
        { rowElement: 'Nationality', rowElementresult: 'Indian' },
        { rowElement: 'Language Proficiency', rowElementresult: 'English and Hindi' },
    ];

    // Application Fee
    const applicationFeeData = [
        { rowElement: 'General/OBC Fee', rowElementresult: '₹250' },
        { rowElement: 'SC/ST Fee', rowElementresult: '₹250' },
        { rowElement: 'Application Fee for Females', rowElementresult: '₹250' },
        { rowElement: 'Fee Payment Mode', rowElementresult: 'Online Only' },
    ];

    // Important Links
    const importantLinkData = [
        { rowElement: 'Official Notification', rowElementresult: 'Download Here', resultLink: 'https://afcat.cdac.in/AFCAT/assets/images/news/AFCAT_02_2024/Advertisement_AFCAT_02-2024.pdf' },
        { rowElement: 'Application Form', rowElementresult: 'Apply Online', resultLink: 'https://afcat.cdac.in/afcatreg/candidate/login' },
        { rowElement: 'Admit Card Download', rowElementresult: 'Access Here', resultLink: 'https://afcat.cdac.in/afcatreg/candidate/login' },
        { rowElement: 'Results Announcement', rowElementresult: 'Check Results', resultLink: 'https://afcat.cdac.in/afcatreg/candidate/login' },
    ];

    // Category-wise Vacancy Detail
    const categoryWiseVacancyDetail = [
        { postName: 'Air Force Common Admission Test (AFCAT)', ur: "NA", sc:"NA", st: "NA", obc: "NA", ews: "NA" },
    ];

    return (
        <>
            <Createnewjob 
                URL='https://afcat.cdac.in/'
                mainHead={"Air Force Common Admission Test (AFCAT) Examination Result 2024"}
                head={"Air Force Common Admission Test (AFCAT) Examination Result 2024"}
                ageLimitData={ageLimitData}
                eligibilityData={eligibilityData}
                dateData={dateData}
                importantLinkData={importantLinkData}
                applicationFeeData={applicationFeeData}
                vacancyDetails={vacancyDetails}
                categoryWiseVacancyDetail={categoryWiseVacancyDetail}
            />
        </>
    );
}

export default AirForceCommonAdmissionTest;
